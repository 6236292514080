import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { BiCopy } from "react-icons/bi";
import SidebarLayout from "../components/shared/SidebarLayout";
import { useForm } from "react-hook-form";
import AuthContext from "../context/AuthContext";
import { apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import BetSlipContext from "../context/BetSlipContext";
const P2ptransfer = () => {
  let { profileData, userCoins, refreshAmount } = useContext(AuthContext);
  const { setMessage, message } = useContext(BetSlipContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  // useEffect(() => {
  //   setMessage({
  //     ...message,
  //     status: true,
  //     errorType: "P2P-Success",
  //     message: `P2P $10:00 PBU transfer successfully`,
  //   });
  // },[])
  const onSubmit = async (body) => {
    if (profileData?._id !== body?.recipient) {
      const { status, data: response_users } = await apiPost(
        apiPath.p2ptransfer,
        body
      );
      if (status === 200) {
        if (response_users.success) {
          refreshAmount();
          reset();
          setMessage({
            ...message,
            status: true,
            errorType: "P2P-Success",
            message: response_users?.message,
          });
        } else {
          setMessage({
            ...message,
            status: true,
            errorType: "P2P",
            message: "Please check you Balance and Recipient wallet ID.",
          });
        }
      } else {
        setMessage({
          ...message,
          status: true,
          errorType: "P2P",
          message: "Please check you Balance and Recipient wallet ID.",
        });
      }
    } else {
      setMessage({
        ...message,
        status: true,
        errorType: "P2P",
        message: "Please check you Recipient wallet ID.",
      });
    }
  };
  const [show, setShow] = useState(false);
  const textAreaRef = useRef(null);
  const copied = async (e) => {
    setShow(true);
    const textField = document.createElement("textarea");
    textField.innerText = profileData?._id;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    // window.navigator?.clipboard?.writeText(profileData?._id);
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  const NumberInputNew = (event) => {
    if (event) {
      if (
        !["Backspace", "Delete", "Tab"].includes(event.key) &&
        !/[0-9]/.test(event.key)
      ) {
        event.preventDefault();
      }
    }
  };
  return (
    <SidebarLayout heading={"P2P Transfer"}>
      <div className="p-3 pt-4 inner-sidebar-content mobile-height-layout">
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="p2transfer-form login-panel"
        >
          <Form.Label>My Wallet ID</Form.Label>
          <div className="bg-white rounded p-3 position-relative d-flex align-items-center wallet-form-control">
            <Form.Control
              type="text"
              disabled
              className=""
              ref={textAreaRef}
              value={profileData?._id}
            />
            <Button onClick={copied} className="theme-btn px-2 ms-2">
              <BiCopy className="fs-3" />
            </Button>
            {show && <div className="overlay-wallet">Copied</div>}
          </div>

          <div className="mt-4">
            <Form.Label>Transfer To</Form.Label>
            <div className="bg-white rounded p-3 ">
              <FloatingLabel
                controlId="floatingInput"
                label="Recipient wallet ID"
                className="mb-sm-4 mb-3"
              >
                <Form.Control
                  {...register("recipient", {
                    required: "Please enter wallet ID",
                    pattern: {
                      value: /^[\w-_.]*$/,
                      message: "Please enter valid ID",
                    },
                    maxLength: {
                      value: 30,
                      message: "Wallet ID should not greater than 30",
                    },
                  })}
                  type="text"
                  className={errors?.recipient?.message ? "error-input" : ""}
                  placeholder="Recipient wallet ID"
                />
                {errors?.recipient?.message && (
                  <span className="text-danger" style={{ fontSize: "12px" }}>
                    {errors?.recipient?.message}
                  </span>
                )}
              </FloatingLabel>
              <div className="pbu-floating">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Transfer Amount"
                  className="mb-sm-4 mb-3"
                >
                  <Form.Control
                    {...register("amount", {
                      required: "Please enter amount",
                      validate: (value) => {
                        const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
                        if (validated == null) {
                          return "After decimal only 2 values are allowed";
                        }
                        if (value > userCoins?.balance) {
                          return `Amount should not be greater than ${userCoins?.balance}`;
                        }
                      },
                    })}
                    onKeyDown={NumberInputNew}
                    type="number"
                    className={errors?.amount?.message ? "error-input" : ""}
                    placeholder="Transfer Amount"
                  />
                  <span className="position-absolute top-0 d-flex align-items-center h-100 text-center px-3 border-right border-end-1 border-1">
                    PBU
                  </span>
                </FloatingLabel>
                {errors?.amount?.message && (
                  <span
                    className="text-danger"
                    style={{ fontSize: "12px", border: "none" }}
                  >
                    {errors?.amount?.message}
                  </span>
                )}
              </div>
              <FloatingLabel
                controlId="floatingInput"
                label="Remark"
                className="mb-sm-4 mb-3"
              >
                <Form.Control
                  {...register("remark", {
                    required: {
                      value: false,
                      message: "Please enter remark",
                    },
                    maxLength: {
                      value: 50,
                      message: "Remark should not greater than 50",
                    },
                  })}
                  type="text"
                  placeholder="Remark"
                />
              </FloatingLabel>

              <Row className="gx-3 mt-3">
                <Col xs={6}>
                  <Button
                    onClick={() => reset()}
                    className="bg-white border-dark text-dark w-100 h-100 fs-5"
                  >
                    Clear
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button type="submit" className="theme-btn w-100" disabled={true} >
                    Transfer
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </div>
    </SidebarLayout>
  );
};

export default P2ptransfer;
